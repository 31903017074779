import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const VerifyOtp =  React.lazy(() => import('./views/pages/register/VerifyOtp'))
const EmailVerification = React.lazy(() => import('./views/pages/register/emailVerification'))
const CreateBusiness = React.lazy(() => import('./views/pages/register/createBusiness'))
const BankSetup = React.lazy(() => import('./views/pages/register/bankSetup'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const MainPage = React.lazy(() => import('./views/pages/login/mainPage'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgot_password/forgot_password'))
const Otp = React.lazy(() => import('./views/pages/forgot_password/otp'))
const SetPassword = React.lazy(() => import('./views/pages/forgot_password/setpassword'))
const AboutUS = React.lazy(() => import('./homeScreen/aboutUs'))
const Pricing = React.lazy(() => import('./homeScreen/pricing'))
const Solution = React.lazy(() => import('./homeScreen/solution'))
const Support = React.lazy(() => import('./homeScreen/support'))
const TermCondition = React.lazy(() => import('./homeScreen/term&condition'))



class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/forgot-password" name="Forgot Password Page" element={<ForgotPassword />} />
            <Route exact path="/otp" name="Otp Page" element={<Otp />} />
            <Route exact path="/setpassword" name="Set Password Page" element={<SetPassword />} />
            <Route exact path="/" name="Login Page" element={<MainPage />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/verify" name="Verify Page" element={<VerifyOtp />} />

            <Route exact path="/email-verification" name="Register Page" element={<EmailVerification />} />
            <Route exact path="/create-business" name="Register Page" element={<CreateBusiness />} />
            <Route exact path="/banksetup" name="Register Page" element={<BankSetup />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/about" name="About" element={<AboutUS />} />
            <Route exact path="/pricing" name="Pricing" element={<Pricing />} />
            <Route exact path="/solution" name="Solution" element={<Solution />} />
            <Route exact path="/term_condition" name="Term Conditions" element={<TermCondition />} />
            <Route exact path="/support" name="Support" element={<Support />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
